import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Home | Codestack Capital',
  meta: [],
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: 'images/LineTech-2.svg', // Added link for the favicon
    }
  ],
  style: [],
  script: [],
};

export default function Index() {
  function toggleMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.toggle('hidden');
  }

  function closeMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.add('hidden');
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 pb-20 bg-gray-50 overflow-hidden'>
          
          <div className='container mx-auto px-4'>
            <div className='mb-6'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-white rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/Untitled-150-40-px.svg'
                          alt=''
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            {' '}
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' href='#' onClick={toggleMenu}>
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50 lg:hidden'>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/Untitled-150-40-px.svg'
                              alt=''
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#111827'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mb-9 Portfolio'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className='bg-white overflow-hidden rounded-t-3xl'>
              <div className='px-8 pt-20'>
                <div className='md:max-w-2xl text-center mx-auto'>
                  <span className='inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                    CODESTACK CAPITAL
                  </span>
                  <h1 className='font-heading mb-6 text-5xl lg:text-6xl text-gray-900 font-black tracking-tight'>
                    We Acquire, Invest &amp; Grow Software Firms
                  </h1>
                  <p className='mb-8 text-xl font-bold'>
                    We help you find areas to improve, offer expert insights,
                    and support your software expansion. We're dedicated to your
                    success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='icon_section py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='px-4 bg-white border border-gray-100 rounded-3xl'>
              <div className='flex flex-wrap items-center py-11 -m-8'>
                <div className='w-full sm:w-1/3 lg:w-1/6 p-8'>
                  <img
                    className='mx-auto'
                    src='images/stubpanda4.webp'
                    alt=''
                  />
                </div>
                <div className='w-full sm:w-1/3 lg:w-1/6 p-8'>
                  <img
                    className='mx-auto'
                    src='images/Add-a-heading-1-png-1692742887370.webp'
                    alt=''
                  />
                </div>
                <div className='w-full sm:w-1/3 lg:w-1/6 p-8'>
                  <img
                    className='mx-auto'
                    src='images/FittedRims-png-1692741647231.webp'
                    alt=''
                  />
                </div>
                <div className='w-full sm:w-1/3 lg:w-1/6 p-8'>
                  <img
                    className='mx-auto'
                    src='images/Quickie.svg'
                    alt=''
                  />
                </div>
                <div className='w-full sm:w-1/3 lg:w-1/6 p-8'>
                  <img
                    className='mx-auto'
                    src='images/Untitled-design-2-png-1692743039081.webp'
                    alt=''
                  />
                </div>
                <div className='w-full sm:w-1/3 lg:w-1/6 p-8'>
                  <img
                    className='mx-auto'
                    src='images/The-DNA-Company-Logo-black.svg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden hidden'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 h-full bg-gray-900 border border-gray-800 rounded-3xl'>
                  <h2 className='font-heading mb-6 text-5xl md:text-6xl text-blue-500 font-black tracking-tight'>
                    Capital Infusion
                  </h2>
                  <h3 className='font-heading mb-2 text-xl text-gray-300 font-bold'>
                    We drive software growth by strategically infusing capital
                    into your business either as an acquisition or investment.
                  </h3>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 h-full bg-gray-900 border border-gray-800 rounded-3xl'>
                  <h2 className='font-heading mb-6 text-5xl md:text-6xl text-blue-500 font-black tracking-tight'>
                    Operations
                  </h2>
                  <h3 className='font-heading mb-2 text-xl text-gray-300 font-bold'>
                    We use diverse methodologies like software audits, to
                    pinpoint operational weaknesses &amp; optimize
                    profitability.
                  </h3>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 h-full bg-gray-900 border border-gray-800 rounded-3xl'>
                  <h2 className='font-heading mb-6 text-5xl md:text-6xl text-blue-500 font-black tracking-tight'>
                    Development
                  </h2>
                  <h3 className='font-heading mb-2 text-xl text-gray-300 font-bold'>
                    We connect with world-class developers, enabling you to
                    create top-tier software of unparallel quality.
                  </h3>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 h-full bg-gray-900 border border-gray-800 rounded-3xl'>
                  <h2 className='font-heading mb-6 text-5xl md:text-6xl text-blue-500 font-black tracking-tight'>
                    Expansion Support
                  </h2>
                  <h3 className='font-heading mb-2 text-xl text-gray-300 font-bold'>
                    Leveraging our extensive network, we facilitate market
                    expansion within your industry.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-900 border border-gray-800 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <h2 className='font-heading max-w-xl mb-20 text-4xl md:text-5xl text-gray-100 font-black tracking-tight'>
                  We know you're busy, so we make things easy.
                </h2>
                <div className='flex flex-wrap -m-3'>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-100 font-black bg-gray-700 rounded-full'>
                          1
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-700 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-100 font-bold'>
                        Connect
                      </h3>
                      <p className='text-gray-500 font-bold'>
                        Get in touch and engage with our M&amp;A experts.
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-white font-black bg-blue-500 rounded-full'>
                          2
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-700 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-100 font-bold'>
                        Business Analysis
                      </h3>
                      <p className='text-gray-500 font-bold'>
                        We analyze your business model to identify growth
                        opportunities.
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-100 font-black bg-gray-700 rounded-full'>
                          3
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-700 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-100 font-bold'>
                        Synergies
                      </h3>
                      <p className='text-gray-500 font-bold'>
                        We connect with our existing partners and assess
                        potential synergies.
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-100 font-black bg-gray-700 rounded-full'>
                          4
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-700 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-100 font-bold'>
                        We Invest
                      </h3>
                      <p className='text-gray-500 font-bold'>
                        After establishing a strategic fit, we invest in your
                        software endeavor.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-white border border-gray-100 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='mb-12 max-w-lg mx-auto text-center'>
                  <h2 className='font-heading mb-6 text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                    Grow fast, Grow right.
                  </h2>
                  <p className='text-gray-500 font-bold'>
                    Racing for growth is exciting, but growing right secures the
                    highest value for your software enterprise.
                  </p>
                </div>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full md:w-1/2 p-4'>
                    <div className='py-14 px-8 text-center h-full bg-gray-100 rounded-3xl'>
                      <div className='md:max-w-xs mx-auto'>
                        <h2 className='font-heading mb-6 text-5xl md:text-6xl lg:text-7xl text-gray-900 font-black tracking-tight'>
                          <span className='text-transparent bg-clip-text bg-gradient-green-dark'>
                            98%
                          </span>
                        </h2>
                        <h3 className='font-heading mb-3.5 text-xl text-gray-900 font-bold'>
                          Satisfaction Rate
                        </h3>
                        <p className='text-gray-600 font-bold'>
                          Our commitment is to ensure our clients' satisfaction.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-4'>
                    <div className='py-14 px-8 text-center h-full bg-gray-100 rounded-3xl'>
                      <div className='md:max-w-xs mx-auto'>
                        <h2 className='font-heading mb-6 text-5xl md:text-6xl lg:text-7xl text-gray-900 font-black tracking-tight'>
                          <span className='text-transparent bg-clip-text bg-gradient-orange'>
                            100%
                          </span>
                        </h2>
                        <h3 className='font-heading mb-3.5 text-xl text-gray-900 font-bold'>
                          Response Rate
                        </h3>
                        <p className='text-gray-600 font-bold'>
                          We value transparency and are here to address all your
                          questions promptly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pt-10 pb-16 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='px-8 pt-16 bg-gray-100 border-t border-l border-r border-gray-100 rounded-t-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap items-center justify-between -m-4 pb-12'>
                  <div className='w-full md:w-1/2 p-4'>
                    <span className='inline-block mb-2 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                      Testimonials
                    </span>
                    <h2 className='font-heading text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                      Our Clients
                    </h2>
                  </div>
                  <div className='w-full md:w-1/2 p-4'>
                    <div className='flex flex-wrap md:justify-end -m-2'>
                      <div className='w-auto p-2' />
                      <div className='w-auto p-2' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='relative px-8'>
              <div className='max-w-7xl mx-auto'>
                <div className='absolute top-0 left-0 w-full h-1/2 bg-gray-100 border-l border-r border-b border-gray-100 rounded-b-3xl' />
                <div className='relative z-10 flex flex-nowrap -m-4'>
                  <div className='flex-shrink-0 max-w-sm p-4'>
                    <div className='flex flex-col justify-between p-8 h-full bg-white border border-gray-100 rounded-3xl shadow-md'>
                      <div className='flex-initial mb-14'>
                        <svg
                          className='mb-4'
                          width={32}
                          height={32}
                          viewBox='0 0 32 32'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.787 15.5068H4.53366C4.64033 9.28012 5.86699 8.25346 9.69366 5.98679C10.1337 5.72012 10.2803 5.16012 10.0137 4.70679C9.76033 4.26679 9.18699 4.12012 8.74699 4.38679C4.24033 7.05346 2.66699 8.68012 2.66699 16.4268V23.6135C2.66699 25.8935 4.52033 27.7335 6.78699 27.7335H10.787C13.1337 27.7335 14.907 25.9601 14.907 23.6135V19.6135C14.907 17.2801 13.1337 15.5068 10.787 15.5068Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M25.2134 15.5065H18.9601C19.0667 9.27988 20.2934 8.25321 24.1201 5.98655C24.5601 5.71988 24.7067 5.15988 24.4401 4.70655C24.1734 4.26655 23.6134 4.11988 23.1601 4.38655C18.6534 7.05321 17.0801 8.67988 17.0801 16.4399V23.6265C17.0801 25.9065 18.9334 27.7465 21.2001 27.7465H25.2001C27.5467 27.7465 29.3201 25.9732 29.3201 23.6265V19.6265C29.3334 17.2799 27.5601 15.5065 25.2134 15.5065Z'
                            fill='#3B82F6'
                          />
                        </svg>
                        <p className='text-lg text-gray-700 font-bold'>
                          Thought it was too early for an investment. But the
                          capital allowed us double down on marketing and expand
                          into new states."
                        </p>
                      </div>
                      <div className='flex-initial'>
                        <div className='flex flex-wrap -m-2'>
                          <div className='w-auto p-2' />
                          <div className='flex-1 p-2'>
                            <h3 className='font-heading text-lg text-gray-900 font-black'>
                              Alexis Morgan, CA
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex-shrink-0 max-w-sm p-4'>
                    <div className='flex flex-col justify-between p-8 h-full bg-white border border-gray-100 rounded-3xl shadow-md'>
                      <div className='flex-initial mb-14'>
                        <svg
                          className='mb-4'
                          width={32}
                          height={32}
                          viewBox='0 0 32 32'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.787 15.5068H4.53366C4.64033 9.28012 5.86699 8.25346 9.69366 5.98679C10.1337 5.72012 10.2803 5.16012 10.0137 4.70679C9.76033 4.26679 9.18699 4.12012 8.74699 4.38679C4.24033 7.05346 2.66699 8.68012 2.66699 16.4268V23.6135C2.66699 25.8935 4.52033 27.7335 6.78699 27.7335H10.787C13.1337 27.7335 14.907 25.9601 14.907 23.6135V19.6135C14.907 17.2801 13.1337 15.5068 10.787 15.5068Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M25.2134 15.5065H18.9601C19.0667 9.27988 20.2934 8.25321 24.1201 5.98655C24.5601 5.71988 24.7067 5.15988 24.4401 4.70655C24.1734 4.26655 23.6134 4.11988 23.1601 4.38655C18.6534 7.05321 17.0801 8.67988 17.0801 16.4399V23.6265C17.0801 25.9065 18.9334 27.7465 21.2001 27.7465H25.2001C27.5467 27.7465 29.3201 25.9732 29.3201 23.6265V19.6265C29.3334 17.2799 27.5601 15.5065 25.2134 15.5065Z'
                            fill='#3B82F6'
                          />
                        </svg>
                        <p className='text-lg text-gray-700 font-bold'>
                          A simple tech audit helped us recognize we were paying
                          way too much for our AWS servers."
                        </p>
                      </div>
                      <div className='flex-initial'>
                        <div className='flex flex-wrap -m-2'>
                          <div className='w-auto p-2' />
                          <div className='flex-1 p-2'>
                            <h3 className='font-heading text-lg text-gray-900 font-black'>
                              Morgan Young, NY
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex-shrink-0 max-w-sm p-4'>
                    <div className='flex flex-col justify-between p-8 h-full bg-white border border-gray-100 rounded-3xl shadow-md'>
                      <div className='flex-initial mb-14'>
                        <svg
                          className='mb-4'
                          width={32}
                          height={32}
                          viewBox='0 0 32 32'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.787 15.5068H4.53366C4.64033 9.28012 5.86699 8.25346 9.69366 5.98679C10.1337 5.72012 10.2803 5.16012 10.0137 4.70679C9.76033 4.26679 9.18699 4.12012 8.74699 4.38679C4.24033 7.05346 2.66699 8.68012 2.66699 16.4268V23.6135C2.66699 25.8935 4.52033 27.7335 6.78699 27.7335H10.787C13.1337 27.7335 14.907 25.9601 14.907 23.6135V19.6135C14.907 17.2801 13.1337 15.5068 10.787 15.5068Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M25.2134 15.5065H18.9601C19.0667 9.27988 20.2934 8.25321 24.1201 5.98655C24.5601 5.71988 24.7067 5.15988 24.4401 4.70655C24.1734 4.26655 23.6134 4.11988 23.1601 4.38655C18.6534 7.05321 17.0801 8.67988 17.0801 16.4399V23.6265C17.0801 25.9065 18.9334 27.7465 21.2001 27.7465H25.2001C27.5467 27.7465 29.3201 25.9732 29.3201 23.6265V19.6265C29.3334 17.2799 27.5601 15.5065 25.2134 15.5065Z'
                            fill='#3B82F6'
                          />
                        </svg>
                        <p className='text-lg text-gray-700 font-bold'>
                          Helped me recognize the importance of recurring
                          revenues and converted my legacy software to a SAAS
                          model."
                        </p>
                      </div>
                      <div className='flex-initial'>
                        <div className='flex flex-wrap -m-2'>
                          <div className='w-auto p-2' />
                          <div className='flex-1 p-2'>
                            <h3 className='font-heading text-lg text-gray-900 font-black'>
                              Emily James, MI
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex-shrink-0 max-w-sm p-4'></div>
                  <div className='flex-shrink-0 max-w-sm p-4'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 md:px-16 bg-gradient-red rounded-3xl'>
              <div className='flex flex-wrap items-center -m-4'>
                <div className='w-full md:w-1/2 p-4'>
                  <h2 className='font-heading text-4xl md:text-5xl text-white font-black tracking-tight'>
                    Ready to fuel your growth? Let’s start.
                  </h2>
                </div>
                <div className='w-full md:w-1/2 p-4'>
                  <div className='flex flex-wrap md:justify-end -m-2'>
                    <div className='w-auto p-2'>
                      <a
                        className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-gray-900 hover:bg-gray-800 focus:ring-4 focus:ring-gray-600 rounded-full'
                        href='/contactus'
                      >
                        Get in Touch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 mb-8 bg-white rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap items-center justify-between -m-4'>
                  <div className='w-auto p-4'>
                    <a className='inline-block' href='/'>
                      <img
                        src='images/Untitled-150-40-px.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-4'>
                    <ul className='-m-6'>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/'
                        >
                          Home
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/portfolio'
                        >
                          Portfolio
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourteam'
                        >
                          Our Team
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourapproach'
                        >
                          Criteria
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/thedevelopmentfund'
                        >
                          Fund
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/contactus'
                        >
                          Contact Us
                        </a>
                      </li>
                      

                    </ul>
                  </div>
                  <div className='w-auto p-4'>
                    <div className='flex flex-wrap -m-4'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

