import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Our Team | Codestack Capital',
  meta: [],
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: 'images/LineTech-2.svg', // Added link for the favicon
    }
  ],
  style: [],
  script: [],
};

export default function OurTeam() {
  function toggleMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.toggle('hidden');
  }

  function closeMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.add('hidden');
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 pb-20 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-6'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-white border border-gray-100 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/Untitled-150-40-px.svg'
                          alt=''
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' href='#' onClick={toggleMenu}>
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50 lg:hidden'>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/Untitled-150-40-px.svg'
                              alt=''
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#111827'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className='relative bg-white overflow-hidden rounded-t-3xl'>
              <div className='absolute w-full h-full bg-white border-t border-l border-r border-gray-100 rounded-t-3xl' />
            </div>
          </div>
        </section>
        <section className='py-16 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-16 max-w-xl mx-auto text-center'>
              <span className='inline-block mb-2 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                Our team
              </span>
              <h2 className='font-heading text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                The Brains Behind it All
              </h2>
            </div>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between px-8 pt-10 pb-12 h-full text-center bg-gray-100 rounded-3xl'>
                  <div className='flex-initial mb-8'>
                    <img
                      className='mb-8 mx-auto rounded-3xl'
                      src='images/Screenshot-2023-08-29-225048-png-1693364527751.webp'
                      alt=''
                    />
                    <h3 className='font-heading mb-2 text-2xl text-gray-900 font-black tracking-tight'>
                      Ben Harrison
                    </h3>
                    <p className='text-sm text-gray-500 font-bold'>
                      &nbsp;Chief Executive Officer
                    </p>
                  </div>
                  <div className='flex-initial'></div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between px-8 pt-10 pb-12 h-full text-center bg-gray-100 rounded-3xl'>
                  <div className='flex-initial mb-8'>
                    <img
                      className='mb-8 mx-auto rounded-3xl'
                      src='images/Untitled-design-25.png'
                      alt=''
                    />
                    <h3 className='font-heading mb-2 text-2xl text-gray-900 font-black tracking-tight'>
                      Chris Harrington
                    </h3>
                    <p className='text-sm text-gray-500 font-bold'>
                      Chief Finance Officer
                    </p>
                  </div>
                  <div className='flex-initial'></div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between px-8 pt-10 pb-12 h-full text-center bg-gray-100 rounded-3xl'>
                  <div className='flex-initial mb-8'>
                    <img
                      className='mb-8 mx-auto rounded-3xl'
                      src='images/avatar-gen114679e8c6588512b97f4e4d82eec876-jpg-1693363741838.webp'
                      alt=''
                    />
                    <h3 className='font-heading mb-2 text-2xl text-gray-900 font-black tracking-tight'>
                      Jamal Foster
                    </h3>
                    <p className='text-sm text-gray-500 font-bold'>
                      Vice President, M&amp;A
                    </p>
                  </div>
                  <div className='flex-initial'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-16 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between px-8 pt-10 pb-12 h-full text-center bg-gray-100 rounded-3xl'>
                  <div className='flex-initial mb-8'>
                    <img
                      className='mb-8 mx-auto rounded-3xl'
                      src='images/Untitled-design-28.png'
                      alt=''
                    />
                    <h3 className='font-heading mb-2 text-2xl text-gray-900 font-black tracking-tight'>
                      Nick Anderson
                    </h3>
                    <p className='text-sm text-gray-500 font-bold'>
                      Director of Strategy, M&amp;A
                    </p>
                  </div>
                  <div className='flex-initial'></div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between px-8 pt-10 pb-12 h-full text-center bg-gray-100 rounded-3xl'>
                  <div className='flex-initial mb-8'>
                    <img
                      className='mb-8 mx-auto rounded-3xl'
                      src='images/Untitled-design-22.png'
                      alt=''
                    />
                    <h3 className='font-heading mb-2 text-2xl text-gray-900 font-black tracking-tight'>
                      Cathy Whitman
                    </h3>
                    <p className='text-sm text-gray-500 font-bold'>
                      Director of Marketing
                    </p>
                  </div>
                  <div className='flex-initial'></div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between px-8 pt-10 pb-12 h-full text-center bg-gray-100 rounded-3xl'>
                  <div className='flex-initial mb-8'>
                    <img
                      className='mb-8 mx-auto rounded-3xl'
                      src='images/Untitled-design-19.png'
                      alt=''
                    />
                    <h3 className='font-heading mb-2 text-2xl text-gray-900 font-black tracking-tight'>
                      Will Preston
                    </h3>
                    <p className='text-sm text-gray-500 font-bold'>
                      Business Development, Manager&nbsp;
                    </p>
                  </div>
                  <div className='flex-initial'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-16 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-16 max-w-xl mx-auto text-center'></div>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between px-8 pt-10 pb-12 h-full text-center bg-gray-100 rounded-3xl'>
                  <div className='flex-initial mb-8'>
                    <img
                      className='mb-8 mx-auto rounded-3xl'
                      src='images/Untitled-design-14-png-1693363534597.webp'
                      alt=''
                    />
                    <h3 className='font-heading mb-2 text-2xl text-gray-900 font-black tracking-tight'>
                      Emily Lawson&nbsp;
                    </h3>
                    <p className='text-sm text-gray-500 font-bold'>
                      Senior Associate, M&amp;A
                    </p>
                  </div>
                  <div className='flex-initial'></div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between px-8 pt-10 pb-12 h-full text-center bg-gray-100 rounded-3xl'>
                  <div className='flex-initial mb-8'>
                    <img
                      className='mb-8 mx-auto rounded-3xl'
                      src='images/Untitled-design-20.png'
                      alt=''
                    />
                    <h3 className='font-heading mb-2 text-2xl text-gray-900 font-black tracking-tight'>
                      Andre Brooks
                    </h3>
                    <p className='text-sm text-gray-500 font-bold'>
                      Senior Associate, Strategy
                    </p>
                  </div>
                  <div className='flex-initial'></div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between px-8 pt-10 pb-12 h-full text-center bg-gray-100 rounded-3xl'>
                  <div className='flex-initial mb-8'>
                    <img
                      className='mb-8 mx-auto rounded-3xl'
                      src='images/Untitled-design-26.png'
                      alt=''
                    />
                    <h3 className='font-heading mb-2 text-2xl text-gray-900 font-black tracking-tight'>
                      Kate Bennett
                    </h3>
                    <p className='text-sm text-gray-500 font-bold'>
                      Business Development, Associate
                    </p>
                  </div>
                  <div className='flex-initial'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 mb-8 border border-gray-100 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap items-center justify-between -m-4'>
                  <div className='w-auto p-4'>
                    <a className='inline-block' href='/'>
                      <img
                        src='images/Untitled-150-40-px.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-4'>
                    <ul className='-m-6'>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/'
                        >
                          Home
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/portfolio'
                        >
                          Portfolio
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourteam'
                        >
                          Our Team
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourapproach'
                        >
                          Criteria
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/thedevelopmentfund'
                        >
                          Fund
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/contactus'
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-auto p-4'>
                    <div className='flex flex-wrap -m-4'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

