import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Portfolio | Codestack Capital',
  meta: [],
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: 'images/LineTech-2.svg', // Added link for the favicon
    }
  ],
  style: [],
  script: [],
};

export default function Portfolio() {
  function toggleMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.toggle('hidden');
  }

  function closeMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.add('hidden');
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 pb-20 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-6'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-white border border-gray-100 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/Untitled-150-40-px.svg'
                          alt=''
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                             Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' href='#' onClick={toggleMenu}>
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50 lg:hidden'>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/Untitled-150-40-px.svg'
                              alt=''
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#'  onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#111827'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                           Investor Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className='px-8 py-20 bg-white overflow-hidden border border-gray-100 rounded-3xl'>
              <div className='md:max-w-2xl text-center mx-auto'>
                <h1 className='font-heading mb-6 text-5xl lg:text-6xl text-gray-900 font-black tracking-tight'>
                  <span>Our Companies&nbsp;</span>
                </h1>
                <div className='max-w-lg mx-auto'>
                  <div className='flex flex-wrap -m-2'>
                    <div className='w-full md:w-auto p-2'>
                      <div className='flex flex-wrap justify-center -m-2'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-white rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap -m-8 mb-10'>
                  <div className='w-full md:w-1/2 p-8'>
                    <div className='md:max-w-lg'>
                      <h2 className='font-heading mb-6 text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                        KNOCK IT! PRO
                      </h2>
                      <p className='mb-8 text-xl font-bold'>
                        Knock it! Pro represents a web-enabled and mobile
                        platform that encompasses all the essential outdoor
                        sales tools required for Insurance Agents.
                      </p>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='https://www.knockitpro.com'
                            target='_blank'
                          >
                            Visit Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-8'>
                    <img
                      className='mx-auto md:mr-0 rounded-3xl'
                      src='images/Untitled-design-9.png'
                      alt=''
                    />
                  </div>
                </div>
                <div className='p-8 md:p-12 bg-gray-100 rounded-3xl'>
                  <div className='flex flex-wrap -m-8'>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-white rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Investment Type
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Invested Capital
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-white rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Year&nbsp;
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            2023
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-white rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Headquarters
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            San Francisco, CA
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-100 border border-gray-100 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap -m-8 mb-10'>
                  <div className='w-full md:w-1/2 p-8'>
                    <div className='md:max-w-lg'>
                      <h2 className='font-heading mb-6 text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                        STUBPANDA
                      </h2>
                      <p className='mb-8 text-xl font-bold'>
                        StubPanda is a convenient and exciting e-ticketing
                        software platform. Your best-night-ever is just a click
                        away, offering seamless event bookings and unforgettable
                        memories.
                      </p>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='https://www.stubpanda.com/'
                            target='_blank'
                          >
                            Visit Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-8'>
                    <img
                      className='mx-auto md:mr-0 rounded-3xl'
                      src='images/Untitled-design-13.png'
                      alt=''
                    />
                  </div>
                </div>
                <div className='p-8 md:p-12 bg-white rounded-3xl'>
                  <div className='flex flex-wrap -m-8'>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Investment Type
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Acquisition
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Year
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            2021
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Headquarters
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Toronto, ON
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-white rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap -m-8 mb-10'>
                  <div className='w-full md:w-1/2 p-8'>
                    <div className='md:max-w-lg'>
                      <h2 className='font-heading mb-6 text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                        ShipQuickie
                      </h2>
                      <p className='mb-8 text-xl font-bold'>
                        ShipQuickie stands as a premier courier rate shipping
                        and e-commerce platform, thoughtfully engineered to
                        revolutionize the logistics landscape.
                      </p>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='https://www.shipquickie.com/'
                            target='_blank'
                          >
                            Visit Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-8'>
                    <img
                      className='mx-auto md:mr-0 rounded-3xl'
                      src='images/Quickie-svg-1692750440608.webp'
                      alt=''
                    />
                  </div>
                </div>
                <div className='p-8 md:p-12 bg-gray-100 rounded-3xl'>
                  <div className='flex flex-wrap -m-8'>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-white rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Investment Type
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Acquisition
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-white rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Year
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            2020
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-white rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Headquarters
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Toronto, ON
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-100 border border-gray-100 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap -m-8 mb-10'>
                  <div className='w-full md:w-1/2 p-8'>
                    <div className='md:max-w-lg'>
                      <h2 className='font-heading mb-6 text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                        FITTEDRIMS
                      </h2>
                      <p className='mb-8 text-xl font-bold'>
                        FittedRims elevates the tire shopping experience, a
                        software that enables tire shops to deliver a truly
                        professional and tailored service.
                      </p>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='https://shop.fittedrims.com/'
                            target='_blank'
                          >
                            Visit Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-8'>
                    <img
                      className='mx-auto md:mr-0 rounded-3xl'
                      src='images/FittedRims-png-1692743480215.webp'
                      alt=''
                    />
                  </div>
                </div>
                <div className='p-8 md:p-12 bg-white rounded-3xl'>
                  <div className='flex flex-wrap -m-8'>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Investment Type
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Invested Capital
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Year
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            2022
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Headquarters
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Toronto, ON
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-white rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap -m-8 mb-10'>
                  <div className='w-full md:w-1/2 p-8'>
                    <div className='md:max-w-lg'>
                      <h2 className='font-heading mb-6 text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                        DATAFLUENT
                      </h2>
                      <p className='mb-8 text-xl font-bold'>
                        Datafluent was acquired with the intention of aiding our
                        current portfolio of businesses. It boasts the finest
                        Silicon Valley engineers, globally renowned for their
                        excellence, dedicated to benefiting our enterprises.
                      </p>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='https://www.fluentindata.com/'
                            target='_blank'
                          >
                            Visit Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-8'>
                    <img
                      className='mx-auto md:mr-0 rounded-3xl'
                      src='images/Untitled-design-2-png-1692743039081.webp'
                      alt=''
                    />
                  </div>
                </div>
                <div className='p-8 md:p-12 bg-gray-100 rounded-3xl'>
                  <div className='flex flex-wrap -m-8'>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-white rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Investment Type
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Acquisition{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-white rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Year
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            2021
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-white rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Headquarters
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            San Jose, CA
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-100 border border-gray-100 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap -m-8 mb-10'>
                  <div className='w-full md:w-1/2 p-8'>
                    <div className='md:max-w-lg'>
                      <h2 className='font-heading mb-6 text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                        THE DNA COMPANY
                      </h2>
                      <p className='mb-8 text-xl font-bold'>
                        The DNA Company platform enables individuals to gain
                        insight into the optimal health decisions based on their
                        genetic makeup.
                      </p>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='https://www.thednacompany.com/'
                            target='_blank'
                          >
                            Visit Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-8'>
                    <img
                      className='mx-auto md:mr-0 rounded-3xl'
                      src='images/Untitled-design-11.png'
                      alt=''
                    />
                  </div>
                </div>
                <div className='p-8 md:p-12 bg-white rounded-3xl'>
                  <div className='flex flex-wrap -m-8'>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Investment Type
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Development Expertise
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Year
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            2021
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-auto md:w-full lg:w-auto p-3'>
                          <div className='flex items-center justify-center w-12 h-12 bg-gray-100 rounded-xl'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                                fill='#3B82F6'
                              />
                              <path
                                d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='flex-1 p-3'>
                          <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                            Headquarters
                          </h3>
                          <p className='text-sm text-gray-700 font-bold'>
                            Toronto, ON
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 mb-8 bg-white rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap items-center justify-between -m-4'>
                  <div className='w-auto p-4'>
                    <a className='inline-block' href='/'>
                      <img
                        src='images/Untitled-150-40-px.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-4'>
                    <ul className='-m-6'>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/'
                        >
                          Home
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/portfolio'
                        >
                          Portfolio
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourteam'
                        >
                          Our Team
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourapproach'
                        >
                          Criteria
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/thedevelopmentfund'
                        >
                          Fund
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/contactus'
                        >
                          Contact Us
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                  <div className='w-auto p-4'>
                    <div className='flex flex-wrap -m-4'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

