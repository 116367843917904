import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import PortfolioPage from './pages/Portfolio.js';
import OurTeamPage from './pages/Our_team.js';
import ContactUsPage from './pages/Contact_us.js';
import SignInPage from './pages/Sign_in.js';
import OurApproachPage from './pages/Our_approach.js';
import TheDevelopmentFundPage from './pages/The_development_fund.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/portfolio" element={<PortfolioPage />} />
         
      <Route path="/ourteam" element={<OurTeamPage />} />
         
      <Route path="/contactus" element={<ContactUsPage />} />
         
      <Route path="/signin" element={<SignInPage />} />
         
      <Route path="/ourapproach" element={<OurApproachPage />} />
         
      <Route path="/thedevelopmentfund" element={<TheDevelopmentFundPage />} />
    </Routes>
  );
}

export default App;
