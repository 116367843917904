import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState } from 'react';

const meta = {
  title: 'Login | Codestack Capital',
  meta: [],
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: 'images/LineTech-2.svg', // Added link for the favicon
    }
  ],
  style: [],
  script: [],
};

export default function SignIn() {
  const [error, setError] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  

  const handleSignIn = (e) => {
    e.preventDefault();
    setError('Invalid user credentials. Please try again.');
    setForgotPasswordError(''); 
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setForgotPasswordError('Please contact a system administrator with your investor ID to reset your account.');
    setError('');
  };

  function toggleMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.toggle('hidden');
  }

  function closeMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.add('hidden');
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 pb-20 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-6'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-white border border-gray-100 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/Untitled-150-40-px.svg'
                          alt=''
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li >
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' href='#' onClick={toggleMenu}>
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50 lg:hidden'>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/Untitled-150-40-px.svg'
                              alt=''
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#111827'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='p-10 bg-gray-100 rounded-3xl'>
              <div className='flex flex-wrap -m-8'>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='py-12 md:py-32 md:max-w-md mx-auto'>
                    <div className='mb-10 text-center'>
                      <img
                        className='mb-6 mx-auto'
                        src='images/LineTech-2.svg'
                        alt=''
                      />
                      <h3 className='font-heading mb-3 text-3xl text-black font-black tracking-tight'>
                        Sign in to your Investor account
                      </h3>
                    </div>
                    <form onSubmit={handleSignIn}>
                      <div className='flex flex-wrap -m-3'>
                        <div className='w-full p-3'>
                          <label
                            className='block mb-2 text-sm text-gray-500 font-bold'
                            htmlFor='signInLightInput5-1'
                          >
                            Email Address
                          </label>
                          <input
                            className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none border border-gray-200 focus:ring-4 focus:ring-blue-200 rounded-full'
                            id='signInLightInput5-1'
                            type='email'
                            placeholder='Enter email address'
                          />
                        </div>
                        <div className='w-full p-3'>
                          <label
                            className='block mb-2 text-sm text-gray-500 font-bold'
                            htmlFor='signInLightInput5-2'
                          >
                            Password
                          </label>
                          <input
                            className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none border border-gray-200 focus:ring-4 focus:ring-blue-200 rounded-full'
                            id='signInLightInput5-2'
                            type='password'
                            placeholder='*************'
                          />
                        </div>
                        <div className='w-full p-3'>
                          <div className='flex flex-wrap items-center justify-between -m-3'>
                          <div className='w-auto p-3'>
      <div className='flex items-center'>
        <input
          className='opacity-1 absolute h-6 w-6 cursor-pointer rounded-full'
          id='signInLightCheckbox5-1'
          type='checkbox'
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <div className='flex flex-shrink-0 justify-center items-center w-6 h-6 mr-4 text-transparent bg-white border border-gray-200 border-neutral-200 rounded-md'>
          <svg
            width={9}
            height={7}
            viewBox='0 0 9 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.603516 3.77075L2.68685 5.85409L7.89518 0.645752'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <label
          className='text-gray-500 font-bold cursor-pointer'
          htmlFor='signInLightCheckbox5-1'
        >
          Remember me
        </label>
      </div>
    </div>
                            <div className='w-auto p-3'>
                              <a
                                className='text-blue-500 hover:text-blue-600 font-bold'
                                href='#'
                                onClick={handleForgotPassword} 
                              >
                                Forgot password?
                              </a>
                            </div>
                          </div>
                        </div>
                        
                        <div className='w-full p-3'>
                          <div className='flex flex-wrap md:justify-end -m-2'>
                            <div className='w-full p-2'>
                            <a
                               className='block px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                               href=' '
                               onClick={handleSignIn} // Add this onClick event handler
                              >
                              Sign In
                            </a>
                            </div>
                          </div>
                        </div>
                        {forgotPasswordError && (
        <div className='w-full p-3 text-red-500 text-center' style={{ color: "red", textAlign: 'center' }}>
          {forgotPasswordError}
        </div>
      )}
                      </div>
                    </form>
                    {error && (
        <div className='w-full p-3 text-red-500 text-center' style={{color:"red", textAlign:'center'}}>
          <br/>
          {error}
          
        </div>
        
      )}
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='flex flex-col justify-end py-16 px-8 bg-blue-500 text-center h-full rounded-3xl'>
                    <div className='md:max-w-lg mx-auto'>
                      <h3 className='font-heading mb-24 md:mb-48 text-2xl text-white font-bold'>
                        Immediate access to live updates on your investments,
                        our partner network, investor resources, and ongoing
                        potential deals.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 mb-8 border border-gray-100 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap items-center justify-between -m-4'>
                  <div className='w-auto p-4'>
                    <a className='inline-block' href='#'>
                      <img
                        src='images/Untitled-150-40-px.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-4'>
                    <ul className='-m-6'>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/'
                        >
                          Home
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/portfolio'
                        >
                          Portfolio
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourteam'
                        >
                          Our Team
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourapproach'
                        >
                          Criteria
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/thedevelopmentfund'
                        >
                          Fund
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/contactus'
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-auto p-4'>
                    <div className='flex flex-wrap -m-4'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

