import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import  { useState, useRef } from 'react';
import axios from 'axios';



const meta = {
  title: 'Fund | Codestack Capital',
  meta: [],
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: 'images/LineTech-2.svg', // Added link for the favicon
    }
  ],
  style: [],
  script: [
    {
      type: 'text/javascript',
      innerHTML: `
      function changePlaceholder() {
        var input = document.getElementById("contactLightBorderInput2-3");
        var isMobileView = window.innerWidth <= 450; 
      
        if (isMobileView) {
          input.setAttribute("placeholder", "Enter Phone no.");
        } else {
          input.setAttribute("placeholder", "Enter your phone number");
        }
      }
      
      window.addEventListener('resize', changePlaceholder);
      
      window.onload = changePlaceholder;
      `
    }
  ],
};

export default function TheDevelopmentFund() {
  const [formData, setFormData] = useState({
    companyName: '',
    emailAddress: '',
    phone: '',
    fundingRequired: '',
    softwareSolution: '',
    consent: false,
  });
  const checkbox = document.getElementById('contactLightBorderCheckbox2-1');
    if (checkbox) {
      checkbox.checked = false;
    }
  const formRef = useRef(null);

  function handleInputChange(event) {

    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const [successMessage, setSuccessMessage] = useState('');
  async function handleFormSubmit(event) {
    event.preventDefault();
    console.log('Form Data:', formData);

    try {
      const response = await axios.post('http://localhost:3001/api/fund-email', formData);
      console.log('Email sent successfully!', response.data);
      setSuccessMessage('Thanks for your submission! We will get back to you promptly.');
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } catch (error) {
      console.error('Error sending email:', error);
      setSuccessMessage('Error sending message. Please try again.');
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    }
    // formRef.current.reset();

    setFormData({
      companyName: '',
      emailAddress: '',
      phone: '',
      fundingRequired:"",
      softwareSolution:"",
      consent: false,
    });

  }


  function toggleMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.toggle('hidden');
  }

  function closeMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.add('hidden');
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 pb-20 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-6'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-white border border-gray-100 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/Untitled-150-40-px.svg'
                          alt=''
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' href='#' onClick={toggleMenu}>
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50 lg:hidden'>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/Untitled-150-40-px.svg'
                              alt=''
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#111827'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className='px-8 py-20 bg-white overflow-hidden border border-gray-100 rounded-3xl'>
              <div className='md:max-w-2xl text-center mx-auto'>
                <span className='inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                  THE DEVELOPMENT FUND&nbsp;
                </span>
                <h1 className='font-heading mb-6 text-5xl lg:text-6xl text-gray-900 font-black tracking-tight'>
                  <span>Build what you </span>
                  <span className='text-transparent bg-clip-text bg-gradient-orange-light'>
                    imagine
                  </span>
                  <span> without compromise.</span>
                </h1>
                <p className='mb-8 text-xl font-bold'>
                  Secure funding and collaborate with the world's elite
                  developers to bring your vision to life.
                </p>
                <div className='max-w-lg mx-auto'>
                  <div className='flex flex-wrap -m-2'>
                    <div className='w-full md:w-auto p-2'>
                      <div className='flex flex-wrap justify-center -m-2'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <p className='mb-8 text-center text-gray-500 font-bold'>
              Join Forces with Developers from...
            </p>
            <div className='bg-white border border-gray-100 rounded-3xl'>
              <div className='flex flex-wrap items-stretch'>
                <div className='w-full sm:w-1/3 border-b sm:border-r border-gray-100'>
                  <div className='py-12 lg:py-20 px-8 bg-white'>
                    <img
                      className='mx-auto'
                      src='images/Untitled-design-6.svg'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full sm:w-1/3 border-b sm:border-r border-gray-100'>
                  <div className='py-12 lg:py-20 px-8 bg-white'>
                    <img
                      className='mx-auto'
                      src='images/Untitled-design-7.svg'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full sm:w-1/3 border-b border-gray-100'>
                  <div className='py-12 lg:py-20 px-8 bg-white'>
                    <img
                      className='mx-auto'
                      src='images/Untitled-design-8.svg'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full sm:w-1/3 border-b sm:border-b-0 sm:border-r border-gray-100'>
                  <div className='py-12 lg:py-20 px-8 bg-white'>
                    <img
                      className='mx-auto'
                      src='images/Untitled-design-9.svg'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full sm:w-1/3 border-b sm:border-b-0 sm:border-r border-gray-100'>
                  <div className='py-12 lg:py-20 px-8 bg-white'>
                    <img
                      className='mx-auto'
                      src='images/Untitled-design-11.svg'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full sm:w-1/3'>
                  <div className='py-12 lg:py-20 px-8 bg-white'>
                    <img
                      className='mx-auto'
                      src='images/Untitled-design-10.svg'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-end p-10 pt-20 lg:pt-40 bg-white h-full border border-gray-100 rounded-3xl'>
                  <h2 className='font-heading text-3xl text-gray-900 font-bold tracking-tight'>
                    <span>Code Success Rate Increased by&nbsp;</span>
                    <span className='text-4xl font-black text-transparent bg-clip-text bg-gradient-green'>
                      7x
                    </span>
                    <span>&nbsp;</span>
                  </h2>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-end p-10 pt-20 lg:pt-40 bg-white h-full border border-gray-100 rounded-3xl'>
                  <h2 className='font-heading text-3xl text-gray-900 font-bold tracking-tight'>
                    <span>Development Speed Increased By </span>
                    <span className='text-4xl font-black text-transparent bg-clip-text bg-gradient-pink'>
                      9.7x
                    </span>
                    <span>&nbsp;</span>
                  </h2>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-end p-10 pt-20 lg:pt-40 bg-white h-full border border-gray-100 rounded-3xl'>
                  <h2 className='font-heading text-3xl text-gray-900 font-bold tracking-tight'>
                    <span>Over </span>
                    <span className='text-4xl font-black text-transparent bg-clip-text bg-gradient-aqua'>
                      47%
                    </span>
                    <span> Higher User Satisfaction Rate</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-white overflow-hidden rounded-3xl'>
              <div className='max-w-5xl mx-auto'>
                <div className='mb-10 md:max-w-md mx-auto text-center'>
                  <span className='inline-block mb-4 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                    APPLICATION PROCESS
                  </span>
                  <h2 className='font-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-gray-900 font-black tracking-tight'>
                    Apply Now
                  </h2>
                  <p className='text-gray-500 font-bold'>
                    Complete the form below and we will review your application.
                  </p>
                </div>
                 {successMessage && (
        <div className='text-green-500 font-bold text-center success-message'>{successMessage}</div>
      )}
                <form ref={formRef}
                className='p-10 bg-white border border-gray-100 rounded-3xl'
                onSubmit= {handleFormSubmit}>
                  
                  <div className='flex flex-wrap -m-5 mb-1'>
                    <div className='w-full md:w-1/2 p-5'>
                      <label
                        className='block mb-2 text-sm text-gray-500 font-bold'
                        htmlFor='contactLightBorderInput2-1'
                      >
                        Company Name
                      </label>
                      <input
                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-gray-100 placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'
                        id='contactLightBorderInput2-1'
                        type='text'
                        name='companyName'
                        value={formData.companyName}  // Make sure the 'value' is set correctly
                        onChange={handleInputChange} 
                        placeholder='Enter your name'
                      />
                    </div>
                    <div className='w-full md:w-1/2 p-5'>
                      <label
                        className='block mb-2 text-sm text-gray-500 font-bold'
                        htmlFor='contactLightBorderInput2-2'
                      >
                        Emaill address
                      </label>
                      <input
                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-gray-100 placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'
                        id='contactLightBorderInput2-2'
                        type='text'
                        name='emailAddress'
                        value={formData.emailAddress}  // Make sure the 'value' is set correctly
                        onChange={handleInputChange}
                        placeholder='Email address'
                      />
                    </div>
                  </div>
                  <div className='flex flex-wrap -m-5'>
                    <div className='w-full md:w-1/2 p-5'>
                      <label
                        className='block mb-2 text-sm text-gray-500 font-bold'
                        htmlFor='contactLightBorderInput2-3'
                      >
                        Phone
                      </label>
                      <input
                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-gray-100 placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'
                        id='contactLightBorderInput2-3'
                        type='text'
                        name='phone'
                        value={formData.phone}  // Make sure the 'value' is set correctly
                        onChange={handleInputChange}
                        placeholder='Enter your phone number'
                      />
                    </div>
                    <div className='w-full md:w-1/2 p-5'>
                      <label
                        className='block mb-2 text-sm text-gray-500 font-bold'
                        htmlFor='contactLightBorderInput2-4'
                      >
                        Funding Required
                      </label>
                      <input
                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-gray-100 placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'
                        id='contactLightBorderInput2-4'
                        type='text'
                        value={formData.fundingRequired}  // Make sure the 'value' is set correctly
                        onChange={handleInputChange} 
                        placeholder='USD'
                        name='fundingRequired'
                      />
                    </div>
                  </div>
                  <div className='flex flex-wrap -m-3.5'>
                    <div className='w-full p-3.5'>
                      <label
                        className='block mb-2 text-sm text-gray-500 font-bold'
                        htmlFor='contactLightBorderInput2-5'
                      >
                        Software Solution
                      </label>
                      <textarea
                        className='appearance-none px-6 py-5 w-full text-lg text-gray-500 font-bold bg-gray-100 placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-3xl'
                        id='contactLightBorderInput2-5'
                        type='text'
                        rows={8}
                        placeholder='Please share more about your software solution, including its purpose and your industry experience.'
                        name='softwareSolution'
                        value={formData.softwareSolution}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='w-full p-3.5'>
                      <div className='flex flex-wrap items-center -m-2'>
                        <div className='w-full md:w-1/2 p-2'>
                          <div className='flex'>
                            <input
                              className='absolute h-5 w-5'
                              id='contactLightBorderCheckbox2-1'
                              type='checkbox'
                            />
                            <div className='flex  justify-center items-center w-6 h-6 mr-4 text-transparent bg-white border border-gray-200 rounded-md'>
                              <svg
                                width={9}
                                height={7}
                                viewBox='0 0 9 7'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M0.603516 3.77075L2.68685 5.85409L7.89518 0.645752'
                                  stroke='currentColor'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </div>
                            <label
                              className='text-sm text-gray-500 font-bold'
                              htmlFor='contactLightBorderCheckbox2-1'
                            >
                              I’d like to occasionally receive other
                              communication from Codestack Capital and any
                              changes to the fund.
                            </label>
                          </div>
                        </div>
                        <div className='w-full md:w-1/2 p-2'>
                          <div className='flex flex-wrap md:justify-end -m-2'>
                          <div className='w-full md:w-auto p-2'>
                            <button
                    type='submit'
                    className='block w-full px-8 md:px-16 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                  >
                    Apply
                  </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                 
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full md:w-1/3 p-4'></div>
                  <div className='w-full md:w-1/3 p-4'></div>
                  <div className='w-full md:w-1/3 p-4'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 mb-8 border border-gray-100 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap items-center justify-between -m-4'>
                  <div className='w-auto p-4'>
                    <a className='inline-block' href='/'>
                      <img
                        src='images/Untitled-150-40-px.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-4'>
                    <ul className='-m-6'>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/'
                        >
                          Home
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/portfolio'
                        >
                          Portfolio
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourteam'
                        >
                          Our Team
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourapproach'
                        >
                          Criteria
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/thedevelopmentfund'
                        >
                          Fund
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/contactus'
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-auto p-4'>
                    <div className='flex flex-wrap -m-4'>
                      <div className='w-auto p-4'>
                        <a className='block' href='#' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

