import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Criteria | Codestack Capital',
  meta: [],
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: 'images/LineTech-2.svg', // Added link for the favicon
    }
  ],
  style: [],
  script: [],
};

export default function OurApproach() {
  function toggleMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.toggle('hidden');
  }

  function closeMenu() {
    const menu = document.querySelector('.navbar-menu');
    menu.classList.add('hidden');
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 pb-20 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-6'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-white border border-gray-100 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/Untitled-150-40-px.svg'
                          alt=''
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' href='#' onClick={toggleMenu}>
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50 lg:hidden'>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#' >
                            <img
                              src='images/Untitled-150-40-px.svg'
                              alt=''
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#111827'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/portfolio'
                          >
                            Portfolio
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourteam'
                          >
                            Our Team
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/ourapproach'
                          >
                            Criteria
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                            href='/thedevelopmentfund'
                          >
                            Fund
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='/signin'
                          >
                            Investor Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contactus'
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 md:px-16 bg-white rounded-3xl'>
              <div className='flex flex-wrap -m-8'>
                <div className='w-full md:w-1/2 p-8'>
                  <img
                    className='mx-auto md:ml-0 rounded-3xl'
                    src='https://images.unsplash.com/photo-1617777938240-9a1d8e51a47d?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwxMTB8fHNvZnR3YXJlJTIwY29tcGFueXxlbnwwfHx8fDE2OTI3NjU1Njl8MA&ixlib=rb-4.0.3&q=85&w=1920'
                    alt=''
                  />
                </div>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='md:max-w-md'>
                    <span className='inline-block mb-5 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                      Our Criteria{' '}
                    </span>
                    <h2 className='font-heading mb-4 text-4xl text-gray-900 font-black tracking-tight'>
                      <span>What makes a software business </span>
                      <span className='text-blue-500'>exceptional?</span>
                    </h2>
                    <p className='mb-16 font-bold'>
                      How can you distinguish good software companies from the
                      truly exceptional? Our straightforward criteria is as
                      follows:
                    </p>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div className='flex flex-wrap px-3 py-2 bg-gray-100 rounded-full'>
                          <div className='w-auto mr-2 pt-1'>
                            <svg
                              width={12}
                              height={12}
                              viewBox='0 0 12 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                          <div className='flex-1'>
                            <p className='text-sm text-gray-900 font-bold'>
                              Vertical Market Software
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex flex-wrap px-3 py-2 bg-gray-100 rounded-full'>
                          <div className='w-auto mr-2 pt-1'>
                            <svg
                              width={12}
                              height={12}
                              viewBox='0 0 12 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                          <div className='flex-1'>
                            <p className='text-sm text-gray-900 font-bold'>
                              Mission Critical
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex flex-wrap px-3 py-2 bg-gray-100 rounded-full'>
                          <div className='w-auto mr-2 pt-1'>
                            <svg
                              width={12}
                              height={12}
                              viewBox='0 0 12 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                          <div className='flex-1'>
                            <p className='text-sm text-gray-900 font-bold'>
                              Business to Business
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <div className='flex flex-wrap px-3 py-2 bg-gray-100 rounded-full'>
                          <div className='w-auto mr-2 pt-1'>
                            <svg
                              width={12}
                              height={12}
                              viewBox='0 0 12 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z'
                                fill='#3B82F6'
                              />
                            </svg>
                          </div>
                          <div className='flex-1'>
                            <p className='text-sm text-gray-900 font-bold'>
                              Recurring Revenues
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pt-10 pb-16 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='relative'>
              <div className='absolute top-1/2 left-1/2 min-w-max transform -translate-x-1/2 -translate-y-1/2'>
                <div className='absolute bg-gradient-radial-white w-full h-full' />
                <img src='zanrly-assets/images/pattern-gray.png' alt='' />
              </div>
              <div className='relative'>
                <span className='inline-block mb-5 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                  What we found
                </span>
                <h2 className='font-heading mb-12 text-4xl text-gray-900 font-black tracking-tight'>
                  Here’s what we've found.
                </h2>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full md:w-1/2 p-4'>
                    <div className='p-8 md:p-16 text-center h-full bg-gray-100 border border-gray-100 rounded-3xl'>
                      <h3 className='font-heading mb-7 text-4xl text-gray-900 font-black tracking-tight'>
                        Vertical Market Focus
                      </h3>
                      <p className='text-xl font-bold'>
                        Vertical market software offers tailored solutions
                        designed specifically for a particular industry's unique
                        needs, ensuring enhanced efficiency and relevance.
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-4'>
                    <div className='p-8 md:p-16 text-center h-full bg-gray-100 border border-gray-100 rounded-3xl'>
                      <h3 className='font-heading mb-7 text-4xl text-gray-900 font-black tracking-tight'>
                        Mission Critical
                      </h3>
                      <p className='text-xl font-bold'>
                        Mission-critical software usually leads to lower churn
                        as it is essential for core operations. It is vital for
                        maintaining business continuity and meeting crucial
                        objectives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pt-10 pb-16 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 md:p-16 text-center h-full bg-gray-100 border border-gray-100 rounded-3xl'>
                  <h3 className='font-heading mb-7 text-4xl text-gray-900 font-black tracking-tight'>
                    Business to Business
                  </h3>
                  <p className='text-xl font-bold'>
                    Business-to-business software customers tend to have clear,
                    specific needs, fostering deeper, longer-lasting
                    partnerships and a more focused development process.
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 md:p-16 text-center h-full bg-gray-100 border border-gray-100 rounded-3xl'>
                  <h3 className='font-heading mb-7 text-4xl text-gray-900 font-black tracking-tight'>
                    Recurring Revenues
                  </h3>
                  <p className='text-xl font-bold'>
                    Recurring revenues in software provide a steady and
                    predictable income stream, ensuring sustainable growth and
                    the ability to invest in continuous innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 text-center bg-blue-500 rounded-3xl'>
              <div className='md:max-w-lg mx-auto'>
                <h2 className='font-heading mb-6 text-4xl md:text-5xl text-white font-black tracking-tight'>
                  Interested in taking a deeper dive?
                </h2>
                <p className='mb-10 text-xl text-blue-100 font-bold'>
                  Interested in our evaluation process and how we assess
                  software companies for investment? Schedule a brief call, and
                  our M&amp;A experts will be delighted to explore our criteria
                  in greater detail.
                </p>
                <div className='flex flex-wrap justify-center -m-2 mb-4'>
                  <div className='w-auto p-2'>
                    <a
                      className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-gray-900 hover:bg-gray-800 focus:ring-4 focus:ring-gray-600 rounded-full'
                      href='/contactus'
                    >
                      Schedule a Call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 mb-8 bg-white rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap items-center justify-between -m-4'>
                  <div className='w-auto p-4'>
                    <a className='inline-block' href='/'>
                      <img
                        src='images/Untitled-150-40-px.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-4'>
                    <ul className='-m-6'>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/'
                        >
                          Home
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/portfolio'
                        >
                          Portfolio
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourteam'
                        >
                          Our Team
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/ourapproach'
                        >
                          Criteria
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/thedevelopmentfund'
                        >
                          Fund
                        </a>
                      </li>
                      <li className='inline-flex p-6'>
                        <a
                          className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                          href='/contactus'
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-auto p-4'>
                    <div className='flex flex-wrap -m-4'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

